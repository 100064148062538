import { fetchHttp } from '@cyberkongz/ui';
import { APIEcosystemSummarySuccessResponse } from '@pages/api/ecosystem-summary';
import { useQuery } from '@tanstack/react-query';

export function useAPIEcosystemSummary() {
  const query = useQuery({
    queryKey: ['/api/ecosystem-summary'],
    queryFn: () => fetchHttp<APIEcosystemSummarySuccessResponse>('/api/ecosystem-summary'),
    retry: false,
  });

  return query;
}
